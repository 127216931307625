import React from 'react';
import { BsFillMoonFill, BsSunFill } from 'react-icons/bs';

type ThemeToggleProps = {
    className?: string | null;
}

const ThemeToggle: React.FC<ThemeToggleProps> = ({className}) => {
    const [theme, setTheme] = React.useState(localStorage.getItem('theme') || 'light');
    const baseClassName = className ? `${className}__theme-toggle` : '';

    const toggleTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        console.log("Toggling theme to", newTheme);  // Ajoutez ceci
        localStorage.setItem('theme', newTheme);
        setTheme(newTheme);
        document.documentElement.setAttribute('data-theme', newTheme);
    };

    React.useEffect(() => {
        document.documentElement.setAttribute('data-theme', theme);
    }, [theme]);

    return (
        <div className={`${baseClassName} theme-toggle`.trim()}>
            <label aria-hidden="true">
                <input 
                    type="checkbox" 
                    id="theme-toggle-checkbox" 
                    checked={theme === 'dark'} 
                    onChange={toggleTheme} 
                    aria-label="Toggle dark mode" 
                />
                <BsFillMoonFill className='theme-toggle__moon'/>
                <BsSunFill className='theme-toggle__sun'/>
                <span className='theme-toggle__toggle'></span>
                {/* <span className='theme-toggle__animate-bg'></span> */}
            </label>
        </div>
    );
};

export default ThemeToggle;