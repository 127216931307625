// recoilState.ts

import {atom} from 'recoil';
import {AppData, SitesInfo} from './types';

export const serverListState = atom < {
    id: string,
    ip: string
}[] > ({key: 'serverListState', default: []});

export const currentServerState = atom < string > ({key: 'currentServerState', default: ''});

export const appDataState = atom < AppData | null > ({key: 'appDataState', default: null});

export const allSitesState = atom < SitesInfo | null > ({key: 'allSitesState', default: null});

export const authState =  atom < boolean > ({key: 'authState', default: false});