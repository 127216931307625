import { useEffect } from 'react';

const useDataRefresh = (refreshInterval: number, fetchDataFunction: () => void) => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchDataFunction();
    }, refreshInterval);

    return () => clearInterval(intervalId);
  }, [fetchDataFunction, refreshInterval]);
};

export default useDataRefresh;