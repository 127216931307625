import React, {useState, useEffect, useRef} from 'react';
import {AppData, StorageInfo, SitesInfo} from '../../services/types';
import storageStringToNumber from '../../services/storageStringToNumber';
import Sites from '../Sites/Sites';
import {FaCheck, FaExclamationTriangle, FaTimes} from 'react-icons/fa';
import {PieChart} from '@mui/x-charts';
import {Box} from '@mui/material';
import StorageProgressBar from '../common/StorageProgressBar';
import SearchableDropdown from '../common/SearchableDropdown';

type ServerProps = {
    serverInfo: AppData | null;
    serverList: {
        id: string,
        ip: string
    }[];
    onServerChange: (serverId : string) => void;
    currentServerId: string;
    allSites: SitesInfo | null;
    siteToServerMapping: {
        [domain : string]: string
    };
    serverNameMapping: {
        [serverId : string]: string
    };
};

const Server : React.FC < ServerProps > = ({
    serverInfo,
    serverList,
    onServerChange,
    currentServerId,
    allSites,
    siteToServerMapping,
    serverNameMapping
}) => {

    const [isOpen,
        setIsOpen] = useState < boolean > (false);
    const dropdownRef = useRef < HTMLDivElement > (null);
    const [serverIpMapping,
        setServerIpMapping] = useState < {
        [key : string]: string
    } > ({});
    const [serverDomainMapping,
        setServerDomainMapping] = useState < {
        [key : string]: string
    } > ({});

    const serverIds = serverList.map(server => server.id);

    const determineStorageStatus = (storageSegments : StorageInfo[]) => {
        let maxUsage = 0;
        storageSegments.forEach(segment => {
            const usage = parseInt(segment.use_percent.replace('%', ''));
            if (usage > maxUsage) {
                maxUsage = usage;
            }
        });

        if (maxUsage < 70) {
            return 'good';
        } else if (maxUsage < 90) {
            return 'warning';
        } else {
            return 'critical';
        }
    };

    useEffect(() => {
        if (serverInfo) {
            setServerIpMapping(prevMapping => ({
                ...prevMapping,
                [currentServerId]: serverInfo.server.ip
            }));

            const domains = Object.keys(serverInfo.sites);
            const domainList = domains.join(', ');

            setServerDomainMapping(prevMapping => ({
                ...prevMapping,
                [currentServerId]: domainList
            }));
        }
    }, [serverInfo, currentServerId]);

    useEffect(() => {
        function handleClickOutside(event : MouseEvent) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        }

        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    if (serverInfo === null) 
        return <p>Loading...</p>;
    
    const handleItemClick = (item : string | number | null) => {
        if (typeof item === 'string') {
            onServerChange(item);
            setIsOpen(false);
        }
    };
    console.log('Liste des serveurs:', serverList);
    console.log('ID du serveur actuel:', currentServerId);

    const storageStatus = determineStorageStatus(serverInfo.storage);

    const getColorClass = (value : string | number) => {
        const numValue = typeof value === 'string'
            ? parseFloat(value)
            : value;

        if (numValue < 70) 
            return "text-good";
        if (numValue < 90) 
            return "text-warning";
        return "text-critical";
    };

    const getUpdateSecurityColorClass = (value : string | number) => {
        const numValue = typeof value === 'string'
            ? parseInt(value, 10)
            : value;

        if (numValue <= 5) 
            return "text-good";
        if (numValue <= 15) 
            return "text-warning";
        return "text-critical";
    };

    const getUpdateColorClass = (value : string | number) => {
        const numValue = typeof value === 'string'
            ? parseInt(value, 10)
            : value;

        if (numValue <= 30) 
            return "text-good"; // 0 à 5 updates est considéré comme bon
        if (numValue <= 50) 
            return "text-warning"; // 6 à 15 est un avertissement
        return "text-critical"; // plus de 15 est critique
    };

    const totalSites = Object
        .keys(serverInfo.sites)
        .length;
    const functionalSites = Object
        .values(serverInfo.sites)
        .filter(site => site.status === "ok")
        .length;
    const redirectionSites = Object
        .values(serverInfo.sites)
        .filter(site => site.status === "redirection")
        .length;
    const failedSites = totalSites - functionalSites - redirectionSites;

    const chartData = [
        {
            name: "ok",
            value: functionalSites,
            color: 'hsl(164, 55%, 52%)'
        }, {
            name: "redirection",
            value: redirectionSites,
            color: 'hsl(41, 100%, 50%)'
        }, {
            name: "echec",
            value: failedSites,
            color: 'hsl(344, 100%, 50%)'
        }
    ];

    const filteredChartData = chartData.filter(data => data.value !== 0);

    return (

        <div className='main server'>
            <div className='server__filters'>
                <div className='server__filters-item'>
                    Sélectionner un serveur
                    <SearchableDropdown
                        items={serverIds}
                        selectedItem={currentServerId}
                        onSelect={handleItemClick}
                        labelMapper={serverNameMapping}
                        ipMapping={serverIpMapping}
                        siteNameMapping={serverDomainMapping}
                        className="server"/>
                </div>
                <div>
                    {serverInfo?.timestamp ? `Dernière mise à jour éffectué le ${serverInfo.timestamp.day}/${serverInfo.timestamp.month}/${serverInfo.timestamp.year} à ${serverInfo.timestamp.hour}:${serverInfo.timestamp.minute}`
                            : 'Chargement en cours...'}
                </div>

            </div>
            <div className='server__title title title-200'>Information</div>
            <section className='server__information'>
                <div className='server__info'>
                    <div className='server__label title title-175'>Info</div>
                    <div className='server__name'>
                        <span>Server name</span>{serverInfo.server.name}
                    </div>
                    <div className='server__ip'>
                        <span>Server IP</span>
                        {serverInfo.server.ip}
                    </div>
                    <div
                        className={`server__update-security ${getUpdateSecurityColorClass(serverInfo.server.nb_upgrade_security)}`}>
                        <span>Security update</span>
                        {serverInfo.server.nb_upgrade_security}
                    </div>
                    <div
                        className={`server__update ${getUpdateColorClass(serverInfo.server.nb_update)}`}>
                        <span>Update</span>
                        {serverInfo.server.nb_update}
                    </div>
                    <div
                        className={`server__cpu ${getColorClass(parseFloat(serverInfo.metrics.cpu))}`}>
                        <span>CPU</span> {parseFloat(serverInfo.metrics.cpu).toFixed(1)} %
                    </div>
                    <div
                        className={`server__memory ${getColorClass(parseFloat(serverInfo.metrics.memory))}`}>
                        <span>Memory</span> {parseFloat(serverInfo.metrics.memory).toFixed(1)} %
                    </div>
                    <div
                        className={`server__traffic ${getColorClass(parseFloat(serverInfo.metrics.network.ping))}`}>
                        <span>Traffic</span> value
                    </div>
                    <div
                        className={`server__ping ${getColorClass(parseFloat(serverInfo.metrics.network.ping))}`}>
                        <span>Ping</span> {serverInfo.metrics.network.ping} ms
                    </div>
                    <div
                        className={`server__connexion-active ${getColorClass(parseFloat(serverInfo.metrics.network.ping))}`}>
                        <span>Connexion active</span> {serverInfo.metrics.network.connexion_active}
                    </div>
                </div>
                <div className='server__status'>
                    <div className="server__status-label title title-175">Status</div>
                    <div className="server__status-data">
                        <span>Data</span>
                        <div>
                            {serverInfo.db.status === 'ok'
                                ? <FaCheck className='text-good'/>
                                : <FaTimes className='text-critical'/>}
                        </div>
                    </div>
                    <div className="server__status-storage">
                        <span>Storage</span>
                        <div>
                            {storageStatus === 'good' && <FaCheck className='text-good'/>}
                            {storageStatus === 'warning' && <FaExclamationTriangle className='text-warning'/>}
                            {storageStatus === 'critical' && <FaTimes className='text-critical'/>}
                        </div>
                    </div>
                    <div className="server__status-system">
                        <span>System</span><span>status</span></div>
                    <div className="server__status-sites">
                        <span>Sites</span>
                        <Box display="flex" justifyContent={"center"}>
                            <Box width={200} height={100}>
                                <PieChart
                                    series={[{
                                        data: filteredChartData,
                                        innerRadius: 40,
                                        outerRadius: 20,
                                        paddingAngle: 6,
                                        cornerRadius: 3,
                                        cx: 95,
                                        cy: 50
                                    }
                                ]}/>
                            </Box>
                        </Box>
                    </div>
                </div>
            </section>
            <div className='server__title title title-200'>Détails</div>
            <details className='server__details'>
                <summary>Configuration du serveur</summary>
                <ul>
                    {Object
                        .entries(serverInfo.config)
                        .map(([key, value]) => (
                            <li key={key}>
                                <strong>{key}:</strong>
                                {value}
                            </li>
                        ))}
                </ul>
            </details>
            <div className='server__title title title-200'>Storage</div>
            <section className='server__storage'>
                <ul className='server__storage-list'>
                    {serverInfo
                        .storage
                        .map((storageSegment : StorageInfo, index : number) => {
                            const storageInodeSegment = serverInfo.inode_usage[index];
                            return (
                                <li className='server__storage-item' key={index}>
                                    <span className='server__storage-detail'>Filesystem
                                        <pre>{storageSegment.filesystem}</pre>
                                    </span>
                                    <span className='server__storage-detail'>Mounted on
                                        <pre>{storageSegment.mounted_on}</pre>
                                    </span>
                                    <div className='server__storage-progress'>
                                        <StorageProgressBar
                                            total={storageStringToNumber(storageSegment.size)}
                                            used={storageStringToNumber(storageSegment.used)}
                                            label="used"/>
                                        <StorageProgressBar
                                            total={storageStringToNumber(storageInodeSegment.inodes)}
                                            used={storageStringToNumber(storageInodeSegment.iused)}
                                            label="iused"/>
                                    </div>
                                </li>
                            );
                        })}
                </ul>
            </section>
            <div className='server__title title title-200'>Sites</div>
            <Sites
                sites={allSites}
                siteToServerMapping={siteToServerMapping}
                filterByServer={currentServerId}
                setCurrentServer={onServerChange}
                isMainClass={false}
                className="server"
            />
        </div>
    );
};

export default Server;
