import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {AppData} from '../../services/types';
// import { FaCaretDown } from 'react-icons/fa';
import Dropdown from '../common/Dropdown';

type SitesProps = {
    sites: AppData['sites'] | null;
    setCurrentServer: (serverId : string) => void;
    siteToServerMapping: {
        [domain : string]: string
    };
    filterByServer?: string;
    isMainClass?: boolean | null;
    isMobile?: boolean | null;
    className?: string;
};

const Sites : React.FC < SitesProps > = ({
    sites,
    setCurrentServer,
    siteToServerMapping,
    filterByServer,
    isMainClass,
    isMobile,
    className
}) => {

    const navigate = useNavigate();
    const [selectedHttpCode,
        setSelectedHttpCode] = useState < number | null > (null);
    const [searchTerm,
        setSearchTerm] = useState < string > ('');
    console.log(isMainClass);

    const mainClass = (isMainClass === false)
        ? ''
        : 'main';

    if (sites === null) 
        return <p>Loading...</p>;
    
    const handleSiteClick = (event : React.MouseEvent, domain : string) => {
        if (isMobile) {
            event.stopPropagation();
        }
        event.preventDefault();
        const serverId = siteToServerMapping[domain];
        console.log('siteToServerMapping[domain]:', siteToServerMapping[domain]);
        console.log('domain:', domain);
        
        console.log('Site to server mapping:', siteToServerMapping);
        console.log('Setting current server to:', serverId);
        setCurrentServer(serverId);
        navigate('/server');
    };

    const handleItemClick = (code : string | number | null) => {
        if (typeof code === 'number' || code === null) {
            setSelectedHttpCode(code);
        }
    };

    if (className) className = `${className}__sites`

    const httpCodes = [null, 300, 400, 500];

    return (
        <div className={`${mainClass} ${className} sites`.trim()}>
            <aside className="sites__filters">
                <Dropdown
                    items={httpCodes}
                    selectedItem={selectedHttpCode}
                    onSelect={handleItemClick}
                    labelMapper={{
                    null: 'Tout',
                    300: '300',
                    400: '400',
                    500: '500'
                }}
                    className="sites"
                    closeOnMouseLeave={true}/>
                <input
                    type="text"
                    value={searchTerm}
                    placeholder='Type to search...'
                    onChange={e => setSearchTerm(e.target.value)}
                    aria-label="Search by domain"/>
            </aside>
            <section className='sites__list'>
                {Object
                    .entries(sites)
                    .filter(([siteName, siteInfo]) => {
                        if (filterByServer && siteToServerMapping[siteName] !== filterByServer) 
                            return false;
                        if (selectedHttpCode) {
                            const minRange = selectedHttpCode;
                            const maxRange = selectedHttpCode + 99;
                            const codesArray = siteInfo
                                .http_code
                                .split(' ')
                                .map(Number);
                            if (!codesArray.some(code => code >= minRange && code <= maxRange)) 
                                return false;
                            }
                        if (searchTerm && !siteName.includes(searchTerm)) 
                            return false;
                        return true;
                    })
                    .map(([siteName, siteInfo]) => {
                        if (filterByServer && siteToServerMapping[siteName] !== filterByServer) 
                            return null;
                        let modifier;
                        switch (siteInfo.status) {
                            case "ok":
                                modifier = "ok";
                                break;
                            case "redirection":
                                modifier = "redirection";
                                break;
                            case "echec":
                                modifier = "echec";
                                break;
                            default:
                                break;
                        }
                        return (
                            <div
                                className="sites__link"
                                onClick={(e) => handleSiteClick(e, siteName)}
                                key={siteName}>
                                <article className={`sites__card sites__card--${modifier}`}>
                                    <span className={`sites__name sites__name--${modifier} title`}>{siteName}</span>
                                    <span className={`sites__code sites__code--${modifier}`}>HTTP Code: {siteInfo.http_code}</span>
                                    <span className={`sites__status sites__status--${modifier}`}>Status: {siteInfo.status}</span>
                                </article>
                            </div>
                        );
                    })}
            </section>
        </div>
    );
};

export default Sites;