import React from 'react';
import { Link } from 'react-router-dom';

type LogoProps = {
  className?: string | null;
}

const Logo: React.FC<LogoProps> = ({className}) => {

  const baseClassName = className ? `${className}__logo` : '';

  return (
    <nav className={`${baseClassName} logo`.trim()}>
      <ul>
        <li>  
          <Link to="/">Logo</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Logo;