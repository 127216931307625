// dataService.ts

import {SetterOrUpdater} from 'recoil';
import {AppData, SitesInfo} from './types';

export const fetchServerList = async() :Promise<{
    id: string,
    ip: string
}[]> => {

    try {
        const url = `/data/servers/serverList.json`; 
        const response = await fetch(url);
        const data : {
            id: string,
            ip: string
        }[] = await response.json();
        return data;
    } catch (error) {
        return [];
    }
}

export const fetchData = async(serverId : string, serverIp : string) : Promise<AppData | null> => {
    try {
        const url = `/data/servers/${serverId}.json`; 
        const response = await fetch(url);
        const data : AppData = await response.json();
        return data;
    } catch (error) {
        return null;
    }
};

export const fetchAllSites = async(serverList : {
    id: string,
    ip: string
}[], setAllSites: SetterOrUpdater<SitesInfo | null>, setSiteToServerMapping: SetterOrUpdater<{ [domain: string]: string }>) : Promise<void> => {
    let allSites : SitesInfo = {};
    let siteToServerMapping : {
        [domain : string] : string
    } = {};

    for (const server of serverList) {
        const data = await fetchData(server.id, server.ip);
        if (data && data.sites) {
            allSites = {
                ...allSites,
                ...data.sites
            };

            for (const siteDomain in data.sites) {
                siteToServerMapping[siteDomain] = server.id;
            }
        }
    }

    setAllSites(allSites);
    setSiteToServerMapping(siteToServerMapping); // Ajouter cette ligne
};

export const fetchServerDetails = async(serverId : string, serverIp : string) => {
    try {
        const url = `/data/servers/${serverId}.json`;

        const response = await fetch(url);
        const data : AppData = await response.json();
        return {id: serverId, name: data.server.name};
    } catch (error) {
        return null;
    }
};