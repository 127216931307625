/**
 * Convertit une chaîne de stockage, par exemple "1.9G", en sa valeur numérique en bytes.
 * 
 * @param storageString - La chaîne de stockage à convertir, e.g. "1.9G".
 * @returns La valeur numérique correspondante en bytes.
 */
const storageStringToNumber = (storageString: string): number => {
    // Supprime les espaces et rend la chaîne en majuscules pour la standardisation.
    const standardized = storageString.trim().toUpperCase();

    // Extrait la valeur numérique et le suffixe (par exemple, "1.9" et "G" de "1.9G").
    const value = parseFloat(standardized.slice(0, -1));
    const suffix = standardized.slice(-1);

    // Si la valeur est NaN, renvoie 0.
    if (isNaN(value)) {
        return 0;
    }

    // Définition des multiplicateurs pour chaque suffixe.
    const multipliers: { [key: string]: number } = {
        B: 1,
        K: 1e3,
        M: 1e6,
        G: 1e9,
        T: 1e12
    };

    // Retourne la valeur convertie en bytes.
    return value * (multipliers[suffix] || 1);
};

export default storageStringToNumber;