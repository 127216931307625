import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { FaTachometerAlt, FaServer, FaSitemap } from 'react-icons/fa';
import { Button } from '@mui/material';
import { useSetRecoilState } from 'recoil';
import { authState } from '../../services/recoilState';

type MenuProps = {
  isMenuOpen?: boolean | null,
  isMobile?: boolean | null,
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string | null,
}

const Menu: React.FC<MenuProps> = ({ isMenuOpen, setMenuOpen, isMobile, className }) => {

  const location = useLocation();

  const baseClassName = className ? `${className}__menu` : 'menu';
  const openClassName = isMenuOpen ? `${baseClassName}--open` : '';
  const visibilityClassName = isMenuOpen ? 'open' : '';
  const combinedClassName = `${baseClassName} ${openClassName} ${visibilityClassName}`.trim();
  const setAuth = useSetRecoilState(authState);

  return (
    <nav className={combinedClassName}>
      <ul>
        <li>
          <NavLink
            to="/"
            className={location.pathname === "/" ? "active" : ""}
            onClick={isMobile ? () => setMenuOpen(false) : undefined}
          >
            <FaTachometerAlt />
            <span>Dashboard</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/server" onClick={isMobile ? () => setMenuOpen(false) : undefined}>
            <FaServer />
            <span>Server</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/sites" onClick={isMobile ? () => setMenuOpen(false) : undefined}>
            <FaSitemap />
            <span>Sites</span>
          </NavLink>
        </li>
        <li>
          <Button variant='contained' color='secondary' onClick={() => setAuth(false)}>
            <span>Deconnexion</span>
          </Button>
        </li>
      </ul>
    </nav>
  );
};

export default Menu;