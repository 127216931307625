import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { fetchData, fetchServerList, fetchAllSites, fetchServerDetails } from './services/dataService';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { serverListState, currentServerState, appDataState, allSitesState, authState } from './services/recoilState';
import useDataRefresh from './hooks/useDataRefresh';
import { FaArrowLeft } from 'react-icons/fa';

// Utils
import { useWindowWidth } from './components/utils/useWindowWidth';
import { classNames } from './components/utils/className';

// Navigation pages
import Dashboard from './components/Dashboard/Dashboard';
import Server from './components/Server/Server';
import Sites from './components/Sites/Sites';

// Sidebar components
import Logo from './components/common/Logo';
import Menu from './components/common/Menu';
import ThemeToggle from './components/common/ThemeToggle';

// Global styles
import './styles/css/main.css';
import Login from './components/login/Login';

// ----------------------------------------------------------------

const App: React.FC = () => {

  // -------------------------- State and Utility Functions --------------------------

  // Responsive window width
  const windowWidth = useWindowWidth();
  // Mobile check
  const isMobile = windowWidth <= 768;

  // Fetch saved state for menu from local storage
  const savedState = localStorage.getItem('isMenuOpen');
  const initialState = savedState !== null ? JSON.parse(savedState) : (window.innerWidth > 768);

  // App state
  const [data, setData] = useRecoilState(appDataState);
  const [serverList, setServerList] = useRecoilState(serverListState);
  const [currentServer, setCurrentServer] = useRecoilState(currentServerState);
  const setAllSites = useSetRecoilState(allSitesState);
  const allSites = useRecoilValue(allSitesState);
  const [siteToServerMapping, setSiteToServerMapping] = useState<{ [domain: string]: string }>({});
  const [serverNameMapping, setServerNameMapping] = useState<{ [serverId: string]: string }>({});
  const [isMenuOpen, setMenuOpen] = useState(initialState);
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);
  const [auth, setAuth] = useRecoilState(authState);

  // Class name computations
  const sidebarClassName = "sidebar";
  const openClassName = isMenuOpen ? `${sidebarClassName}--open` : '';
  const hiddenClassName = isMobile && !isMenuOpen ? `${sidebarClassName}--hidden` : '';
  const hiddenExpandedSidebarClassName = isMobile ? `${sidebarClassName}__toggle--hidden` : '';
  const openExpandedSidebarClassName = isMenuOpen ? `${sidebarClassName}__toggle--open` : '';

  const expandedSidebarClassName = classNames(
    `${sidebarClassName}__toggle`,
    openExpandedSidebarClassName,
    hiddenExpandedSidebarClassName
  );

  const responsiveSidebarClassName = classNames(
    sidebarClassName,
    openClassName,
    hiddenClassName
  );

  // Fonction pour rafraîchir les données du serveur actuel
  const refreshCurrentServerData = () => {
    if (currentServer) {
      const server = serverList.find(s => s.id === currentServer);
      if (server) {
        fetchData(currentServer, server.ip).then(data => {
          if (data) {
            setData(data);
          }
        });
      }
    }
  };

  // Fonction pour rafraîchir toutes les données des sites
  const refreshAllSitesData = () => {
    fetchAllSites(serverList, setAllSites, setSiteToServerMapping);
  };

  // Rafraîchir les données du serveur actuel toutes les 30 minutes
  useDataRefresh(1800 * 1000, refreshCurrentServerData);

  // Rafraîchir toutes les données des sites toutes les heure
  useDataRefresh(3600 * 1000, refreshAllSitesData);

  // -------------------------- Data Fetching and Side Effects --------------------------

  // Fetch the list of servers on mount
  useEffect(() => {
    if(!auth){ return; }
    fetchServerList().then((list) => {
      setServerList(list);
      if (list.length > 0) setCurrentServer(list[0].id);
    });
  
  }, [setCurrentServer, setServerList, auth]);

  useEffect(() => {
    if(!auth){ return; }
    const savedServer = localStorage.getItem('currentServer');
    if (savedServer) {
      setCurrentServer(savedServer);
    }
  }, [setCurrentServer, auth]);

  useEffect(() => {
    if (currentServer) {
      localStorage.setItem('currentServer', currentServer);
    }
  }, [currentServer]);

  useEffect(() => {
    if (currentServer) {
      const server = serverList.find(s => s.id === currentServer);
      if (server) {
        fetchData(currentServer, server.ip).then(data => {
          if (data) {
            setData(data);
            console.log('Fetching data for server', currentServer);
          }
        });
      }
    }
  }, [currentServer, serverList, setData]);

  // Fetch all sites data
  useEffect(() => {
    fetchAllSites(serverList, setAllSites, setSiteToServerMapping);
  }, [serverList, setAllSites]);

  useEffect(() => {
    Promise.all(serverList.map(server => fetchServerDetails(server.id, server.ip))).then(serverDetails => {
      const newServerNameMapping: { [key: string]: string } = {};
      serverDetails.forEach(detail => {
        if (detail) {
          newServerNameMapping[detail.id] = detail.name;
        }
      });
      setServerNameMapping(newServerNameMapping);
    });
  }, [serverList]);

  useEffect(() => {
    const savedState = localStorage.getItem('isMenuOpen');
    if (savedState !== null) {
      setMenuOpen(JSON.parse(savedState));
    } else if (window.innerWidth > 768) {
      setMenuOpen(true);
    }
  }, []);

  // Save menu open state to local storage
  useEffect(() => {
    localStorage.setItem('isMenuOpen', JSON.stringify(isMenuOpen));
  }, [isMenuOpen]);

  // -------------------------- Event Handlers --------------------------

  // Touch event handlers for mobile sidebar toggle
  const handleTouchStart = (e: React.TouchEvent) => setTouchStartX(e.changedTouches[0].screenX);
  const handleTouchMove = (e: React.TouchEvent) => setTouchEndX(e.changedTouches[0].screenX);
  const handleTouchEnd = () => {
    if (touchEndX > touchStartX + 100) setMenuOpen(true);
    if (touchStartX > touchEndX + 100) setMenuOpen(false);
  };

  // -------------------------- Component Rendering --------------------------
  return (
    <div
      className='wrapper'
      onTouchStart={isMobile ? handleTouchStart : undefined}
      onTouchMove={isMobile ? handleTouchMove : undefined}
      onTouchEnd={isMobile ? handleTouchEnd : undefined}
    >
      <Router>
        {auth &&
          <aside className={responsiveSidebarClassName} >
            <button className={expandedSidebarClassName} onClick={() => setMenuOpen(!isMenuOpen)}>
              <FaArrowLeft className='arrow' />
            </button>
            <Logo className={sidebarClassName} />
            <Menu isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} isMobile={isMobile} className={sidebarClassName} />
            <ThemeToggle className={sidebarClassName} />
          </aside>
        }
        <Routes>
          <>
            {auth &&
              <>
                <Route path="/" element={<> TODO </>} />
                <Route path="/server" element={<Server serverInfo={data} serverList={serverList} onServerChange={setCurrentServer} currentServerId={currentServer} allSites={allSites} siteToServerMapping={siteToServerMapping} serverNameMapping={serverNameMapping} />} />
                <Route path="/sites" element={<Sites sites={allSites} setCurrentServer={setCurrentServer} siteToServerMapping={siteToServerMapping} isMobile={isMobile} />} />
              </>
            }

            {!auth &&
              <Route path="/" element={<Login />} />
            }
          </>
        </Routes>
      </Router>
    </div>
  );
};

export default App;