import React, {useState, useRef, useEffect} from 'react';
import {FaCaretDown, FaCaretLeft} from 'react-icons/fa';

interface SearchableDropdownProps {
    items : (string | number | null | {
        id: string,
        ip: string
    })[];
    selectedItem : string | number | null;
    onSelect : (item : string | number | null) => void;
    labelMapper?: {
        [key : string]: string
    };
    ipMapping?: {
        [key : string]: string
    };
    siteNameMapping?: {
        [key : string]: string
    };
    className?: string;
    closeOnMouseLeave?: boolean;
}

const SearchableDropdown : React.FC < SearchableDropdownProps > = ({
    items,
    selectedItem,
    onSelect,
    labelMapper = {},
    ipMapping = {},
    siteNameMapping = {},
    className,
    closeOnMouseLeave
}) => {
    const [isOpen,
        setIsOpen] = useState < boolean > (false);
    const [searchTerm,
        setSearchTerm] = useState < string > ('');

    const dropdownRef = useRef < HTMLDivElement > (null);
    const searchInputRef = useRef < HTMLInputElement > (null);

    useEffect(() => {
        function handleClickOutside(event : MouseEvent) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                if (searchInputRef.current !== event.target as Node) {
                    setIsOpen(false);
                }
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (isOpen) {
            searchInputRef.current
                ?.focus();
        }
    }, [isOpen]);

    const handleSearchChange = (e : React.ChangeEvent < HTMLInputElement >) => {
        setSearchTerm(e.target.value);
    };

    const filteredItems = items.filter((item) => String(item).toLowerCase().includes(searchTerm.toLowerCase()) || (labelMapper[String(item)] && labelMapper[String(item)].toLowerCase().includes(searchTerm.toLowerCase())) || (ipMapping[String(item)] && ipMapping[String(item)].toLowerCase().includes(searchTerm.toLowerCase())) || (siteNameMapping[String(item)] && siteNameMapping[String(item)].toLowerCase().includes(searchTerm.toLowerCase())));

    return (
        <div
            ref={dropdownRef}
            className={`${className}__dropdown lc-searchable-dropdown`}
            onClick={() => setIsOpen(!isOpen)}>
            <div className={`${className}__select lc-searchable-dropdown__select`}>
                {isOpen
                    ? (<input
                        ref={searchInputRef}
                        className={`${className}__dropdown__search lc-searchable-dropdown__search`}
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Search..."/>)
                    : (
                        <span className={`${className}__selected lc-searchable-dropdown__selected`}>
                            {labelMapper[selectedItem as string] || selectedItem || 'Select an item'}
                        </span>
                    )}
                {isOpen
                    ? <FaCaretDown/>
                    : <FaCaretLeft/>}
            </div>
            {isOpen && (
                <ul className={`${className}__menu lc-searchable-dropdown__menu`}>
                    {filteredItems.map((item, index) => (
                        <li
                            key={index}
                            onClick={() => onSelect(item !== null && typeof item === 'object'
                            ? item.id
                            : item)}>
                            {labelMapper[item as string] || (item !== null && typeof item === 'object'
                                ? item.id
                                : item)}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SearchableDropdown;