import React, { useEffect, useState } from 'react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { authState } from '../../services/recoilState';
import { Box, Button, TextField } from '@mui/material';
import { SHA256 } from 'crypto-js';

const Login = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const setAuth = useSetRecoilState(authState);

	/*useEffect(() => {
		setAuth(true);
	},[])
*/

	const handleLogin = () => {
		// Ici, vous devrez appeler un service d'authentification pour vérifier les informations de connexion.
		// N'oubliez pas de sécuriser la gestion des mots de passe côté serveur.
		// Ne stockez pas les mots de passe en clair dans votre application.

		// Exemple fictif pour la démonstration :

		const emailHash = SHA256(email).toString();
		const mdpHash = SHA256(password).toString();

		if (
			emailHash === '439deed0cbef2df3acfb6d33b5182caea1b6ef6add13acb43e7e19ae30fd935e' &&
			mdpHash === '8f3b0064097d63cd0c09775269dfa3c219af55d7ca38a65a6849aef558068e9e'
		) {
			setAuth(true);
		} else {
			alert('Échec de la connexion');
		}
	};

	return (
		<div style={{
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			width: "100vw",
			marginTop: "40px"
		}}>
			<h1>Connexion</h1>
			<form>
				<Box mt={2}>
					<TextField placeholder='login' required value={email} onChange={(e) => setEmail(e.target.value)} type='text' />
				</Box>
				<br />
				<Box>
					<TextField placeholder='mdp' required value={password} onChange={(e) => setPassword(e.target.value)} type='text' />
				</Box>
				<br />
				<Button variant="contained" onClick={handleLogin}>
					Se connecter
				</Button>
			</form>
		</div>
	);
}

export default Login;
