import React, { useState, useEffect } from 'react';
import { LinearProgress, LinearProgressProps, Box, Typography, styled } from '@mui/material';
// import { useTheme } from '@mui/material/styles';  // <-- Importez useTheme

type StorageProgressBarProps = {
    total: number;
    used: number;
    label: string;
};

interface StyledLinearProgressProps extends LinearProgressProps {
    percentused: number;
}

const StyledLinearProgress = styled(LinearProgress)<StyledLinearProgressProps>(({ theme, percentused }) => ({
    '& .MuiLinearProgress-barColorPrimary': {
        backgroundColor: 
            percentused >= 90
            ? 'red'
            : percentused >= 70
            ? 'orange'
            : percentused < 70
            ? '#40c7a2'
            : theme.palette.primary.main,
    },
    backgroundColor:
        theme.palette.mode === 'light'
            ? 'rgba(0, 0, 0, 0.12)'
            : 'rgba(255, 255, 255, 0.12)',
    // Ici, nous ajoutons le style pour le pourcentage
    '& + div > p': {
        color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
    }
}));

const StorageProgressBar: React.FC<StorageProgressBarProps> = ({ total, used, label }) => {
    const percentused = total !== 0 ? (used / total) * 100 : 0;
    const [currentProgress, setCurrentProgress] = useState(0);

    // const theme = useTheme();
    // const textColor = theme.palette.mode === 'dark' ? '#ffffff' : '#000000';

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentProgress(prevProgress => {
                if (prevProgress < percentused) {
                    return prevProgress + 1;
                }
                clearInterval(interval);
                return prevProgress;
            });
        }, .1);

        return () => clearInterval(interval);
    }, [percentused]);

    return (
        <Box>
            <Typography variant="caption" display="block" gutterBottom>
                {label}
            </Typography>
            <Box display="flex" alignItems="center">
                <Box width="100%" mr={1}>
                    <StyledLinearProgress 
                        variant="determinate" 
                        value={currentProgress}
                        percentused={percentused}
                    />
                </Box>
                <Box minWidth={35}>
                    <Typography variant="body2">
                        {`${Math.round(currentProgress)}%`}
                    </Typography>
                </Box>  
            </Box>
        </Box>
    );
};

export default StorageProgressBar;