import React, { useState, useRef, useEffect } from 'react';
import { FaCaretDown, FaCaretLeft } from 'react-icons/fa';

interface DropdownProps {
    items: (string | number | null)[];
    selectedItem: string | number | null;
    onSelect: (item: string | number | null) => void;
    labelMapper?: { [key: string]: string };
    className?: string;
    closeOnMouseLeave?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({ items, selectedItem, onSelect, labelMapper = {}, className, closeOnMouseLeave }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const currentDropdownRef = dropdownRef.current;
        if (closeOnMouseLeave && currentDropdownRef) {
            const handleMouseLeave = () => setIsOpen(false);
            currentDropdownRef.addEventListener('mouseleave', handleMouseLeave);
        
            return () => {
                currentDropdownRef.removeEventListener('mouseleave', handleMouseLeave);
            };
        }
    }, [closeOnMouseLeave]);

    return (
        <div ref={dropdownRef} className={`${className}__dropdown lc-searchable-dropdown`} onClick={() => setIsOpen(!isOpen)}>
            <div className={`${className}__select lc-searchable-dropdown__select`}>
                <span className={`${className}__selected lc-searchable-dropdown__selected`}>
                    {labelMapper[selectedItem as string] || selectedItem || 'Select an item'}
                </span>
                {isOpen ? <FaCaretDown /> : <FaCaretLeft />}
            </div>
            {isOpen && (
                <ul className={`${className}__menu lc-searchable-dropdown__menu`}>
                    {items.map((item, index) => (
                        <li key={index} onClick={() => onSelect(item)}>
                            {labelMapper[item as string] || item}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Dropdown;